<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<h4 class="p-m-2">Kardex</h4>
				<Toast/>
				<Fieldset legend="Consulta" class="p-mb-1">
						<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12">
							<label for="producto">Producto a Consultar</label>
                            <div class="p-inputgroup">
                                <InputText size="100" id="codigo_consultar" type="text" v-model="filtroProducto" v-on:keyup.enter="buscarProducto"/>				
                                <Button icon="pi pi-search" @click="seleccionarProducto"/>							
                            </div>	                            
						</div>		
						<div v-if="lkpSucursales.length > 1" class="p-field p-col-12">
							<label for="sucursal">Sucursal</label>
							<Dropdown v-model="filtro.id_sucursal" :options="lkpSucursales" optionLabel="sucursal" optionValue="id_sucursal" placeholder="Seleccionar Sucursal" />
						</div>		
						<div v-if="lkpBodegas.length > 1" class="p-field p-col-12">
							<label for="bodega">Bodega</label>
							<Dropdown v-model="filtro.id_bodega" :options="lkpBodegas" optionLabel="bodega" optionValue="id_bodega" placeholder="Seleccionar Bodega" />
						</div>													
						<div class="p-field p-col">
							<label for="Periodo">Período</label>
							<SeleccionFecha @nuevoPeriodo="actualizarPeriodo"/>
						</div>
						<div class="p-field p-col">
							<label for="desde">Desde</label>
							<Calendar id="desde" v-model="filtro.fecha_ini" :key="periodoUpdate" :showIcon="true"/>
						</div>		
						<div class="p-field p-col">
							<label for="hasta">Hasta</label>
							<Calendar id="hasta" v-model="filtro.fecha_fin" :key="periodoUpdate" :showIcon="true"/>
						</div>            							
						<div class="p-field p-col p-mt-4">
							<Button icon="pi pi-search" label="Consultar" class="p-button-primary"  @click="getKardex" :disabled="consultaIncompleta"/>
						</div>									
					</div>	
				</Fieldset>
				<DataTable ref="dt" :value="movimientos" class="p-datatable-sm" :rowClass="rowClass" dataKey="id_movimiento" :paginator="true" :rows="10" :filters="filters"
							selectionMode="single"				
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading">
					<template #header>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6 p-mb-2">
                                <label for="codigo">Código</label>
                                <InputText id="codigo" v-model="selProducto.codigo" type="text" disabled/>
                            </div>    
                            <div class="p-field p-col-12 p-md-6 p-mb-2">
                                <label for="unidad_medida">Unidad de Medida</label>
                                <InputText id="unidad_medida" v-model="selProducto.unidad_medida" type="text" disabled/>
                            </div>                                                
                            <div class="p-fluid p-col-12 p-mb-2">
                                <InputText id="producto" v-model="selProducto.producto" type="text" disabled/>
                            </div>    
                            <div class="p-fluid p-col-12 p-mb-2">
                                <InputText id="descripcion" v-model="selProducto.descripcion" type="text" disabled/>
                            </div>                                                    
                        </div>
                    
					</template>
					<Column field="fecha" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.fecha}}
						</template>
					</Column>         
					<Column field="tipo_trans" header="Tipo" :sortable="true"/>               
					<Column field="documento" header="Documento" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.serie}}-{{slotProps.data.no_docto}}
						</template>
					</Column>
					<Column field="cta_habiente" header="Cta.Habiente"/> 
					<Column field="existencia_bodega" header="Exist. Anterior" :sortable="true">
						<template #body="slotProps">
                            <div class="p-text-bold">
							{{formatCurrency(slotProps.data.existencia_bodega)}}
                            </div>
						</template>
					</Column>
					<Column field="entradas" header="Entrada" :sortable="true">
						<template #body="slotProps">
                            <div v-if="slotProps.data.entradas != 0" class="p-text-bold" style="color: #068c4d">
								{{formatCurrency(slotProps.data.entradas)}}
                            </div>
						</template>
					</Column>         
					<Column field="salidas" header="Salida" :sortable="true" >
						<template #body="slotProps">
                            <div v-if="slotProps.data.salidas != 0" class="p-text-bold" style="color: #9c021e">
							{{formatCurrency(slotProps.data.salidas)}}
                            </div>
						</template>
					</Column>       
					<Column field="nueva_exist_bod" header="Nueva Existencia" :sortable="true" >
						<template #body="slotProps">
                            <div class="p-text-bold">
							{{formatCurrency(slotProps.data.nueva_exist_bod)}}
                            </div>
						</template>
					</Column>                                                  
                    <!-- <ColumnGroup type="footer">
                        <Row>				
                            <Column footer="Total Disponible:" :colspan="3" footerStyle="text-align:right"/>
                            <Column :footer="formatCurrency(existenciaTotal)"/>			
                        </Row>
                    </ColumnGroup>		                     -->
				</DataTable>

                <BuscarProducto ref="dlgBuscaProducto" :mostrar="productoDialog" :tipo="tipoProducto" :mostrar_conversiones="mostrarConversiones" @cancelar="cerrarDlgProducto"  @seleccionar="elegirProducto" :filtro="filtroProducto" :key="productoDlgRefresh"/>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import SeleccionFecha from '../components/SeleccionFecha.vue';
import BuscarProducto from '../components/BuscarProducto.vue';
import InvService from '../service/inv.service';

export default {
	setup() {
		const rowClass = (data) => {
            return data.anulado === 'S' ? 'row-anulada': null;
        };

		return { rowClass }
	},	
	components: { Loading, BuscarProducto, SeleccionFecha },
	data() {
		return {
            lkpSucursales: this.$store.state.auth.currentAppCtx.Sucursales,
			lkpBodegas: this.$store.state.auth.currentAppCtx.Bodegas,
			periodoUpdate: 0,
            mostrarConversiones: "N",
            tipoProducto:"PRODU",
			productoDialog: false,
            selProducto: {codigo: '', producto: '', unidad_medida: '', descripcion: ''},
			productoDlgRefresh: 0,
			filtroProducto: '',
			loading: false,		
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},  
            filtro: {
                id_empresa: this.$store.state.auth.currentAppCtx.id_referencia,
                id_sucursal: this.$store.state.auth.currentAppCtx.Sucursales[0].id_sucursal,
                id_bodega: this.$store.state.auth.currentAppCtx.Bodegas[0].id_bodega,
                param1: 'N',
                fecha_ini: new Date(),
                fecha_fin: new Date(),
                id: ''
            },
			movimientos: null
		}
	},
	computed: {
		consultaIncompleta()
		{
			return (this.filtro.id == '');
		}
	},
	methods: {
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},        
		actualizarPeriodo(periodo)
		{	
            this.filtro.fecha_ini = periodo.desde;
			this.filtro.fecha_fin = periodo.hasta;
			this.periodoUpdate++;
		},	        
		buscarProducto()
		{
			InvService.buscaProductos(this.$store.state.auth.currentAppCtx, this.filtroProducto, this.mostrarConversiones, this.tipoProducto, '', 'S').then(data => 
			{
				if(data.length == 1){
					data[0].mostrar = 'S';
                    this.selProducto = data[0];
					this.elegirProducto(this.selProducto);	
				} else {
					this.$refs.dlgBuscaProducto.mostrarResultadosBusqueda(this.filtroProducto, data);									
					this.productoDialog = true;
				}
				this.productoDialogLoading = false;
			}	
			).catch(
				data => {
                    this.productoDialogLoading = false;
                    this.selProducto = {codigo: '', producto: '', unidad_medida: '', descripcion: ''};
                    this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
                }				
            );			
		},
		seleccionarProducto(){
			if(this.filtroProducto == '') {
				this.productoDialog = true;
				this.dlgBuscarProductoRefresh++;
			} else {
				this.buscarProducto();
			}
		},
		cerrarDlgProducto(){
			this.filtroProducto = '';
			this.productoDialog = false;
		},		
		elegirProducto(Producto){
			this.productoDialog = false;	
			this.selProducto = Producto;
            this.filtro.id = Producto.id_producto_empresa;
			this.getKardex();			
		},
		getKardex(){
			this.loading = true;
			InvService.GetProductoKardex(this.$store.state.auth.currentAppCtx, this.filtro).then(data => 
			{
				this.loading = false;
				this.movimientos = data;
			}	
			).catch(
				data => {
				this.productoDialogLoading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}				
			);	
		},
    }
}
</script>

<style scoped lang="scss">
.anulado {
  color: #bd2130;
  font-size: small;
  font-weight: bold;
  text-align: center;
  margin: auto;  
}

.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}
</style>    

